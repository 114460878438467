import Vue from "vue";
import {date} from "@/common/js/utils";

/* 时间格式化 */
Vue.filter('date', function (value) {
    return value ? date(value) : '----'
})

/* 时间格式化 去T */
Vue.filter('dateFormat', function (value) {
    return value ? value.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ') : '----'
})
