module.exports = {
    fixedHeader: false,
    sidebarLogo: true,
    /* 项目名称 */
    title: '沐恩网络首页',
    /* 本地调试环境 */
    devHost: 'https://xlts.hzgh.org',
    /* 项目版本号 */
    version: '1.0.0',
    /* 文档地址 */
    documentUrl: ''
}
