import Cookies from 'js-cookie'

const TokenKey = 'app_token'
const PREFIX = '_MU'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
   Cookies.remove(TokenKey)
}

export function removeUserInfo() {
  removeStorage('name')
  removeStorage('type')
  removeStorage('id')
}

export function setStorage(k, v) {
  if (!k || !v) return
  return Cookies.set(PREFIX + k, v)
}

export function getStorage(k) {
  if (!k) return
  return Cookies.get(PREFIX + k)
}

export function removeStorage(k) {
  if (!k) return
  Cookies.remove(PREFIX + k)
}
