<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',

    }
</script>

<style>
    body {
        padding: 0;
        margin: 0;
        background: #f0f2f5;
        font-size: 14px;
    }
    .page_container{
        border-radius: 4px;
        box-sizing: border-box;
        padding: 15px 18px;
        background: #ffffff;
    }

    .el-tooltip__popper{
        max-width: 450px;
    }
</style>
