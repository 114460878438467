<template>
  <div class="block" :style="{ backgroundColor: bgColor, height: height}">
    <div class="container">
      <div class="title-box" v-if="title">
        <span class="title" :style="{ color: color }">{{ title }}</span>
        <span class="subtitle" :style="{ color: color }">{{ subtitle }}</span>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-item",
  props: {
    bgColor: {
      type: String,
      default: '#ffffff'
    },
    height: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#333333'
    }
  },
  computed: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  width: 1280px;
  margin: auto;
}

.title-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;

  .title {
    font-size: 36px;
    font-family: AliHYAiHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
}

@media screen and (max-width: 1280px) {
  .block{
    width: 1280px;
  }
}
</style>

