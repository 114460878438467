import {constantRoutes} from '@/router'

const state = {
    routes: [],
    addRoutes: [],
    loadedRoutes: false
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
        state.loadedRoutes = true
    }
}

const actions = {

    resetRoutes() {
        state.addRoutes = []
        state.routes = []
        state.loadedRoutes = false
    },

    generateRoutes({commit},) {
        return new Promise((resolve) => {
            state.loadedRoutes = true
            commit('SET_ROUTES',[])
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
