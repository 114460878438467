// import { login, logout, getInfo } from '@/api/user'
import {getStorage, getToken, removeToken, setStorage, setToken, removeUserInfo} from '@/utils/auth'
import {resetRouter} from '@/router'
import {userLogin} from "@/api/user";
import base from '@/common/js/base'

const getDefaultState = () => {
    return {
        // 接口token
        token: getToken(),
        // 用户编号
        id: getStorage('id'),
        // 用户姓名
        name: getStorage('name'),
        // 用户类型
        type: getStorage('type')
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        setToken(token)
    },
    SET_NAME: (state, name) => {
        state.name = name
        setStorage('name', name)
    },
    SET_TYPE: (state, type) => {
        state.type = type
        setStorage('type', type)
    },
    SET_ID: (state, id) => {
        state.id = id
        setStorage('id', id)
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        console.log(userInfo)
        return new Promise((resolve, reject) => {
            if (userInfo.username !== 'admin' || userInfo.password !== '123456') {
                base.error('账号或者密码失败')
                reject()
            } else {
                commit('SET_ID', 1)
                commit('SET_NAME', 'admin')
                commit('SET_TOKEN', 'token')
                resolve()
            }
            // userLogin({
            //     userName: userInfo.username,
            //     password: userInfo.password
            // }).then(({data: res}) => {
            //     const {name, token, uid} = res.data
            //     commit('SET_ID', uid)
            //     commit('SET_NAME', name)
            //     commit('SET_TOKEN', token)
            //     resolve()
            // }).catch(error => {
            //     reject(error)
            // })

        })
    },

    // user logout
    logout({commit}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            removeToken() // must remove  token  first
            removeUserInfo()
            await resetRouter()
            commit('RESET_STATE')
            resolve()
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

