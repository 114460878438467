export default {
    methods: {
        async $navTo(path, params = {}) {
            await this.$router.push({
                path, params
            }).catch(() => {
                // 忽略报错
            })
        },

        $navBack() {
            this.$router.back()
        },

        $object(obj = {}) {
            Object.keys(obj).map(key => {
                if (!obj[key] && obj[key] !== 0) {
                    delete obj[key]
                }
            })
            return obj
        },

        $proxy(path, proxy = '/proxy') {
            if (Array.isArray(path)) {
                return path.map(item => `${process.env.NODE_ENV === 'development' ? proxy : ''}${item}`)
            } else {
                return `${process.env.NODE_ENV === 'development' ? proxy : ''}${path}`
            }
        },

        // 访问Oss图片资源
        $wrapImg(url) {
            return this.$proxy('/api/file/downloadOrgFile?name=a&&url=' + url)
        },

        $download(path) {
            window.open(path)
        }
    }
}