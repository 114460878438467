const getters = {
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    token: state => state.user.token,
    name: state => state.user.name,
    type: state => state.user.type,
    id: state => state.user.id,
    permission_routes: state => state.permission.routes,
    loadedRoutes: state => state.permission.loadedRoutes,


    villageOptions: state => state.options.villageOptions,  // 所在村社筛选
    statusOptions: state => state.options.statusOptions, // 反馈状态筛选
}
export default getters
