const state = {
    // 村社筛选
    villageOptions: [
        {value: 330604109204, label: '五夫村委会'},
        {value: 330604109206, label: '西陡门村委会'},
        {value: 330604109215, label: '五好村委会'},
        {value: 330604109220, label: '二都村委会'},
        {value: 330604109225, label: '杨溪村委会'},
        {value: 330604109227, label: '白马湖村委会'},
        {value: 330604109237, label: '春晖村委会'},
        {value: 330604109238, label: '新驿亭村委会'},
        {value: 330604109239, label: '五洲村委会'},
        {value: 330604109241, label: '联桥村委会'},
        {value: 330604109242, label: '贾家村委会'},
        {value: 330604109243, label: '鲁溪闸村委会'},
        {value: 330604109244, label: '新横塘村委会'},
        {value: 330604109245, label: '新杨梅村委会'},
    ],
    // 反馈状态
    statusOptions: [
        {label: '处理中', value: 1},
        {label: '已反馈', value: 2},
    ],
}

export default {
    namespaced: true,
    state,
}

