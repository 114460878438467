import axios from 'axios'
import router from '@/router'
import store from '@/store'
import {
    Message
} from "element-ui";
import {getToken} from "@/utils/auth";

const http = axios.create({
    baseURL: '',
    timeout: 1000 * 1200,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
        // 'Content-Type': 'application/x-www-form-urlencoded',
    }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    config.headers['Authorization'] = getToken()
    if (config.url.indexOf('.json') === -1) {
        config.url = process.env.NODE_ENV === 'development' ? `/proxy${config.url}` : `${config.url}`
    }
    return config
}, err => {
    return Promise.reject(err)
})

/**
 * 返回拦截
 */
http.interceptors.response.use(response => {
    return response
}, async err => {
    console.log(err)
    if (err.response.status === 401 || err.response.status === 402) {
        Message.error({
            message: '未登录或验证失败',
            duration: 1500
        })
        await store.dispatch('user/logout')
        await router.push(`/login`).catch(()=>{})
        return Promise.reject(err)
    }else if(err.response.status === 500 ){
        Message.error({
            message: '服务器开了点小差',
            duration: 1500
        })
    }
    return Promise.reject(err)
})

export default http

