import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import elementUI from 'element-ui'
import './element-variables.scss'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import '@/styles/index.scss' // global css
import sectionItem from '@/components/section-item/section-item'
import Row from '@/components/common/layout/row'
import Column from '@/components/common/layout/column'
import {
    date,
    debounce,
    throttle,
} from '@/common/js/utils'
import http from "@/common/js/request";
import mixin from "@/common/mixin/mixin";
import '@/filters/setup'

Vue.use(elementUI, {zhLocale})
Vue.config.productionTip = false

Vue.component('row', Row)
Vue.component('column', Column)
Vue.component('sectionItem', sectionItem)

Vue.mixin(mixin)

Vue.prototype.$srore = store
Vue.prototype.$util = {
    date,
    debounce,
    throttle,
}

Vue.filter('date', function (value) {
    return value ? date(value) : '----'
})

Vue.prototype.$http = http

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
