import {Loading, Message, MessageBox} from 'element-ui'

/**
 * 初始化对话框
 * @param pointer 指向
 * @param props 参数
 */
const initDialog = (pointer, props) => {
    if (pointer && props) {
        const {
            title = '',  // 标题设置
            params = {}, // 参数初始化
            callback // 回调
        } = props
        if (title) pointer['title'] = title
        pointer['callback'] = callback
        pointer['form'] = {}
        if (Object.keys(params).length > 0) {
            pointer['form'] = Object.assign({}, pointer['form'], params)
        }
        pointer['visible'] = true
    }
}

/**
 * 表单校验
 * @param form 表单
 * @param pointer this指向
 * @returns {Promise<unknown>}
 */
const checkForm = (form, pointer) => {
    return new Promise(resolve => {
        const ref = pointer?.$refs[form]
        ref && ref.validate(valid => valid && resolve())
    })
}

/**
 * 确认
 */
const confirm = (message = '确认操作') => {
    return new Promise(resolve => {
        MessageBox.confirm(message, '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => resolve(true)).catch(() => resolve(false))
    })
}

/**
 * 成功消息提示
 * @param message 文字
 * @param duration 时长（毫秒）
 */
const success = (message = '', duration = 1500) => {
    Message.success({
        message,
        duration
    })
}

/**
 * 错误消息提示
 * @param message 文字
 * @param duration 时长（毫秒）
 */
const error = (message = '', duration = 1500) => {
    Message.error({
        message,
        duration
    })
}

let _loading = undefined

/**
 * 全局加载
 * @param props
 */
const loading = (props = {}) => {
    _loading = Loading.service({
        background: 'rgba(0,0,0,0.4)',
        spinner: 'el-icon-loading',
        ...props
    })
}

/**
 * 关闭加载
 */
const hideLoading = () => {
    _loading && _loading.close()
    _loading = undefined
}


/**
 * 警告消息提示
 * @param message 文字
 * @param duration 时长（毫秒）
 */
const warning = (message = '', duration = 1500) => {
    Message.warning({
        message,
        duration
    })
}


export default {
    initDialog,
    checkForm,
    confirm,
    loading,
    hideLoading,
    success,
    error,
    warning
}