<template>
  <div v-if="!item.hidden">
    <template
        v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow && !onlyOneChild.path.includes(':')">
      <app-link :to="resolvePath(onlyOneChild.path)">
        <span class="btn">{{ onlyOneChild.meta.title }}</span>
      </app-link>
    </template>
    <template v-else>
      <el-popover placement="bottom" trigger="hover">
        <span class="btn" type="text" slot="reference">{{ item.meta.title }}</span>
        <el-row type="flex" justify="space-between">
          <div v-for="(child, index) in item.children" :key="index">
            <template v-if="child.path.includes(':')">
              <el-row type="flex" justify="space-between">
                <div v-for="(childItem, childIndex) in child.meta.second" :key="childIndex" class="col-box">
                  <el-col>
                    <div v-if="item.meta.showSubtitle === 'yes'" class="sub-parent">{{ childItem.parent }}</div>
                    <div v-for="(third, thirdIndex) in childItem.third" :key="thirdIndex" class="sub-btn">
                      <span @click="toLink(child.path, third)">{{ third }}</span>
                    </div>
                  </el-col>
                </div>
              </el-row>
            </template>
            <template v-else>
              <el-col style="margin: 0 10px;">
                <app-link :to="resolvePath(child.path)">
                  <span class="sub-btn">{{ child.meta.title }}</span>
                </app-link>
              </el-col>
            </template>
          </div>
        </el-row>
      </el-popover>
    </template>
  </div>
</template>

<script>
import path from 'path'
import {isExternal} from '@/utils/validate'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import * as pathToRegexp from 'path-to-regexp'

export default {
  name: 'NavbarItem',
  components: {AppLink},
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  methods: {
    toLink(routePath, name) {
      var id = {
        id: name
      }
      this.$navTo(pathToRegexp.compile(routePath)(id))
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 80px;

  &:hover {
    cursor: pointer;
    color: #FE5252;
  }
}

.col-box {
  padding: 0 32px;
}

.sub-parent {
  width: 80px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
  padding: 10px 4px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.sub-btn {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 10px 4px;

  &:hover {
    cursor: pointer;
    color: #FE5252;
  }
}
</style>
