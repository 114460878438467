<template>
    <div class="flex-column" :style="{...wrapStyle,...flexFull}" @click="$emit('click')">
        <slot></slot>
    </div>
</template>

<script>
	import {
		prefixFlexArray,
		prefixSpaceArray
	} from './layout-config.js'
    export default {
        name: "column",
		computed: {
			wrapStyle() {
				return {
					...this.wrapFlex('align-items', this.align),
					...this.wrapFlex('justify-content', this.justify)
				}
			},
			flexFull() {
				return this.full ? {
					flex: 1
				} : {}
			}
		},
		props: {
			align: {
				type: String,
				default: ''
			},
			justify: {
				type: String,
				default: ''
			},
			/* 撑满父元素 */
			full: {
				type: Boolean,
				default: false
			}
		},

		methods: {
			/* CSS全称wrapper */
			wrapFlex(key, value) {
				if (!value) return {}
				if (prefixFlexArray.indexOf(value) !== -1) {
					return {
						[key]: `flex-${value}`
					}
				} else if (prefixSpaceArray.indexOf(value) !== -1) {
					return {
						[key]: `space-${value}`
					}
				}
				return {
					[key]: value
				}
			}
		}
    }
</script>

<style lang="scss" scoped>
    .flex-column {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
    }
</style>