<template>
  <div class="navbar-box">
    <div class="navbar">
      <div class="navbar-left">
        <h1>{{webTitle}}</h1>
        <img src="../../assets/logo.svg" style="width:150px"/>
      </div>
      <div class="navbar-right">
        <navbar-item v-for="route in permission_routes" :key="route.path" :base-path="route.path"
                     :item="route"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NavbarItem from './NavbarItem'
import variables from '@/styles/variables.scss'

const app = require('@/settings')
export default {
  components: {NavbarItem},
  computed: {
    ...mapGetters([
      'sidebar',
      'permission_routes'
    ]),
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  data() {
    const hostEnum = {
      'www.tmofamily.com': '沐恩门户网站',
      'www.tihohome.com':'小沐导学',
      'www.tihowindow.com':'智能语音助手',
      'www.munwindow.com':'小沐聊天',
      'www.munfamily.com':'小沐对讲',
      'www.tihofamily.com':'小沐助教',
      'www.moon-mobi.com': '沐恩官网首页',
    }
    const webTitle = hostEnum[window.location.host] || ''
    return {
      webTitle:webTitle,
      title: app.title,
      version: app.version
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-box {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.navbar{
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-right{
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-left{
  display: flex;
  align-items: center;
  h1{
    margin-right: 20px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1280px) {
  .navbar{
    width: 100%;
  }
}
</style>
